<template>
  <div class="tests p-4 w-full overflow-auto border-t border-gray-300 pb-80">


    <div class="flex items-center space-x-4">
      <p-checkbox class="mt-4" v-model="disabled" label="Disabled" />    
      <p-input class="mt-4 w-32" v-model="label" placeholder="Label" />
      <p-input class="mt-4 w-32" v-model="error" placeholder="Error message" />
    </div>

    <hr class="my-4" />

    <p-date-picker class="w-40" :disabled="disabled" v-model="date" :label="label" :error="error" />


    <div class="mt-4 flex flex-col space-y-4">

      <PFile
        class="w-96"
        label="File"
        :disabled="disabled"
        :error="error"
        :allowPaste="true"
        @change="onFile" 
      />

      <PSelect
        class="w-96"
        :items="items"
        :disabled="disabled" 
        :error="error"
        :searchable="true"
        :clearable="true"
        :fixedHeight="false"        
        v-model="selectedItem"
      >        
        <template v-slot:caption="{ item }">
          <div v-if="item">
            <div class="text-lg">{{ item.text }}</div>
            <div class="text-sm">{{ item.someprop }}</div>
          </div>
        </template>
        <template v-slot="{ item }">
          text: {{ item.text }}
        </template>
      </PSelect>

      <PSelect
        class="w-96"
        :items="items"
        :disabled="disabled" 
        :error="error"
        :searchable="true"
        v-model="selectedItem"
      />

      <div class="w-96">
        <p-post-code-picker />      
      </div>

      <p-location-picker 
        class="w-1/3" 
        v-model="location" 
        :pooling="true" 
        :corporate="true" 
        :disabled="disabled" 
        :error="error" 
      />

      <div>{{ location }}</div>

      <p-status-picker 
        v-model="statuses" 
        :disabled="disabled" 
        show-statuses="0,1,2,3,4,5,6" 
        :error="error"
      /> 
      
      {{ statuses }}

      <p-button @click="onClick" :disabled="disabled">Primary</p-button>
      
      <p-button color="secondary" :disabled="disabled">Secondary</p-button>    
      
      <p-button color="danger" :disabled="disabled">Danger</p-button>    

      <p-input v-model="input" :disabled="disabled" :label="label" :wait="500" :error="error" placeholder="Placeholder" /> {{ input }} (debounce:500)

      <p-number class="w-20" v-model="number" :disabled="disabled" :label="label" :error="error" />

      <p-number class="w-20" :value="numberSteps" @input="updateQuantity('x', $event)" :disabled="disabled" :step="17" :min="0" :max="200" label="Step 17 and wait 500" :wait="500" :error="error" /> {{ numberSteps }}
      
      <p-textarea v-model="textarea" :disabled="disabled" :label="label" :error="error"></p-textarea> {{ textarea }}

      <p-option-group v-model="item" :items="items"></p-option-group>

      <p-checkbox :disabled="disabled" v-model="check" :label="label" />

      <p-toggle :disabled="disabled" v-model="toggle" :label="label" />

      <div class="mt-4 flex space-x-6">
        <button class="py-2 border-b-2 border-transparent hover:border-gray-300 select-none hover:text-gray-800 focus:outline-none  focus:border-green-500 focus:text-green-500" :class="{ 'border-green-500 text-green-500': tab === n }" v-for="n in 5" :key="n" @click="tab = n">
          Tab {{ n }}
        </button>
      </div>


      <!-- <p-product-picker :disabled="disabled" v-model="product" :label="label" :error="error"></p-product-picker> {{ product }} -->

    </div>

  </div>
</template>

<script>

import PButton from "@/components/forms/PButton"
import PInput from "@/components/forms/PInput"
import PTextarea from "@/components/forms/PTextarea"
import POptionGroup from "@/components/forms/POptionGroup"
import PCheckbox from "@/components/forms/PCheckbox"
import PToggle from "@/components/forms/PToggle"
import PNumber from "@/components/forms/PNumber"
import PDatePicker from "@/components/forms/PDatePicker"
// import PProductPicker from "@/components/forms/PProductPicker"
import PPostCodePicker from "@/components/forms/PPostCodePicker"

export default {
  name: "test",
  components: {
    PButton,
    PInput,
    PTextarea,
    POptionGroup,
    PCheckbox,
    PToggle,
    PNumber,
    PDatePicker,
    // PProductPicker,
    PPostCodePicker
  },
  data () {
    return {
      label: "Label",
      error: "",
      input: "",
      items: [
        { value: 0, text: "Option 0", someprop: "Hello" },
        { value: 1, text: "Option 1", someprop: "Hello" },
        { value: 2, text: "Option 2", someprop: "Hello" },
        { value: 3, text: "Option 3", someprop: "Hello" },
        { value: 4, text: "Option 4", someprop: "Hello" },
        { value: 5, text: "Option 5", someprop: "Hello" },
        { value: 6, text: "Option 6", someprop: "Hello" },
        { value: 7, text: "Option 7", someprop: "Hello" },
        { value: 8, text: "Option 8", someprop: "Hello" },
        { value: 9, text: "Option 9", someprop: "Hello" },
      ],
      item: 2,
      selectedItem: 2,
      textarea: "",
      radio: 1,
      number: 0,
      numberSteps: 0,
      check: false,
      toggle: false,
      disabled: false,
      date: "2020-08-30",
      product: null,
      tab: 1,
      statuses: "2,3",
      location: "",
    }
  },
  methods: {
    onClick() {
      console.log('click')
    },
    onFile (files) {
      console.log(files)
    },
    updateQuantity (s, quantity) {
      console.log(s, quantity)
    }
  },
  // watch: {
  //   numberSteps: function (val) {
  //     console.log("controls value:", val)
  //   }
  // }
}
</script>

